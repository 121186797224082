<template>
  <div>
    <b-row>
      <div style="margin-left: 20px; margin-right: 20px; width: 100%">
        <h5
          class="label-color text-center font-size-print"
          style="font-size: 18px; margin-bottom: 10px"
        >
          PHIẾU BÁN HÀNG
        </h5>
        <b-row style="margin-top: 50px">
          <b-col md="8">
            <p class="content-info font-size-print">
              <span class="label-color font-size-print">Chi nhánh</span>
              : {{ bill.storeAddress }}
            </p>
            <p class="content-info font-size-print">
              <span class="label-color font-size-print">Tổng đài CSKH</span>:
              {{ bill.storeHotline }}
            </p>
            <p class="content-info font-size-print">
              <span class="label-color font-size-print">Đơn hàng</span>
              : {{ bill.billNumber }}
            </p>
            <p class="content-info font-size-print">
              <span class="label-color font-size-print">Ngày</span>
              : {{ bill.createdAt }}
            </p>
          </b-col>
          <b-col md="4">
            <p class="content-info font-size-print">
              <span class="label-color font-size-print">Tên khách hàng</span>
              : {{ bill.customerName }}
            </p>
            <p class="content-info font-size-print">
              <span class="label-color font-size-print">Điện thoại</span>
              : {{ bill.customerPhone }}
            </p>
            <p class="content-info font-size-print">
              <span class="label-color font-size-print">Địa chỉ</span>
              : {{ bill.customerAddress }}
            </p>
          </b-col>
        </b-row>

        <p class="label-color font-size-print">Danh sách sản phẩm</p>

        <table class="table table-bordered-print col-md-12 mb-10">
          <tr>
            <th class="label-color text-center font-size-print">STT</th>
            <th class="label-color font-size-print">Tên sản phẩm</th>
            <th class="label-color font-size-print">Bảo hành</th>
            <th class="label-color font-size-print">SL</th>
            <th class="label-color font-size-print">Đơn giá</th>
            <th class="label-color font-size-print">Chiết khấu</th>
            <th class="label-color font-size-print">Thành tiền</th>
          </tr>
          <tr v-for="(item, index) in bill.listBillItem" :key="index">
            <td class="text-center align-middle">
              <p v-if="item.billItemType === BILL_ITEM_TYPE_PRODUCT">
                {{ item.stt }}
              </p>
            </td>
            <td>
              <p
                v-show="item.type === PRODUCT_TYPE.PRODUCT || item.type === PRODUCT_TYPE.PRODUCT_IMEI || item.type === PRODUCT_TYPE.PRODUCT_SERVICE"
                class="m-0 font-size-print"
                v-if="item.billItemType === BILL_ITEM_TYPE_PRODUCT"
              >
                {{ item.name }}
              </p>

              <p
                v-if="item.type === PRODUCT_TYPE.PRODUCT_IMEI"
                style="font-style: italic"
                class="font-size-gift"
              >
                IMEI: {{ item.imeiCode }}
              </p>
              <p
                v-show="item.type === PRODUCT_TYPE.PRODUCT || item.type === PRODUCT_TYPE.PRODUCT_IMEI || item.type === PRODUCT_TYPE.PRODUCT_SERVICE"
                class="ml-0 font-size-gift"
                v-else-if="item.billItemType === BILL_ITEM_TYPE_PRODUCT_BONUS"
              >
                <i style="color: rgb(24, 28, 50)" class="fas fa-gift ml-4"></i>
                {{ item.name }}
              </p>
              <p
                v-show="item.type === PRODUCT_TYPE.PRODUCT || item.type === PRODUCT_TYPE.PRODUCT_IMEI || item.type === PRODUCT_TYPE.PRODUCT_SERVICE"
                class="ml-0"
                v-else-if="item.billItemType === BILL_ITEM_TYPE_PRODUCT_PROMOTION"
              >
                <i style="color: rgb(24, 28, 50)" class="fas fa-tags ml-4"></i>
                {{ item.name }}
              </p>
              <div
                class="table-responsive"
                v-show="
                  item.comboItems !== undefined && item.comboItems.length > 0
                "
              >
                <table>
                  <tr>
                    <td style="border: none">
                      <p class="m-0 font-size-print">
                        {{ item.name }}
                      </p>
                    </td>
                  </tr>
                  <tr
                    v-for="(items, i) in item.comboItems"
                    :key="i"
                    style="border-top: 1px solid gray"
                  >
                    <td
                      style="border: none; width: -webkit-fill-available"
                      class="font-size-gift"
                    >
                      <i style="color: rgb(24, 28, 50)" class="fas fa-cube"></i>
                      {{ items.productName }}
                    </td>
                  </tr>
                </table>
              </div>
            </td>
            <td class="text-right align-middle font-size-print">
              <p v-if="item.warrantyMonthNo">
                <span v-if="item.warrantyMonthNo === -1">Bảo hành trọn đời</span>
                <span v-else>{{ item.warrantyMonthNo }} tháng</span>
              </p>
            </td>
            <td class="text-right align-middle font-size-print">
              {{ item.quantity }}
            </td>
            <td class="text-right align-middle font-size-print">
              <p v-if="item.billItemType === BILL_ITEM_TYPE_PRODUCT || item.billItemType === BILL_ITEM_TYPE_PRODUCT_PROMOTION">
                {{ convertPrice(item.price) }}
              </p>
            </td>
            <td class="text-right align-middle font-size-print">
              <p v-if="item.billItemType === BILL_ITEM_TYPE_PRODUCT && item.discountType === AMOUNT_TYPE_MONEY">
                {{ convertPrice(item.discount) }}
              </p>
              <p
                v-if="
                  item.billItemType === BILL_ITEM_TYPE_PRODUCT &&
                  item.discountType === AMOUNT_TYPE_PERCENT &&
                  item.discountAmount === 0
                "
              >
                {{ convertPrice(item.discount) }}
              </p>
              <p
                v-if="
                  item.billItemType === BILL_ITEM_TYPE_PRODUCT &&
                  item.discountType === AMOUNT_TYPE_PERCENT &&
                  item.discountAmount > 0
                "
              >
                {{ convertPrice(item.discountAmount) }}%
                <br />
                <span
                  style="font-style: italic"
                  v-if="
                    item.billItemType === BILL_ITEM_TYPE_PRODUCT &&
                    item.discountType === AMOUNT_TYPE_MONEY &&
                    item.discountAmount > 0
                  "
                  >{{ convertPrice(item.discount) }}</span
                >
                <span
                  style="font-style: italic"
                  v-if="
                    item.billItemType === BILL_ITEM_TYPE_PRODUCT &&
                    item.discountType === AMOUNT_TYPE_PERCENT &&
                    item.discountAmount > 0
                  "
                  >{{ convertPrice(item.discount * item.quantity) }}</span
                >
              </p>
            </td>
            <td class="text-right align-middle font-size-print">
              <p v-if="item.billItemType === BILL_ITEM_TYPE_PRODUCT || item.billItemType === BILL_ITEM_TYPE_PRODUCT_PROMOTION">
                {{ item.totalAmountItem }}
              </p>
            </td>
          </tr>
          <tr>
            <td :colspan="3"></td>
            <td class="text-right align-middle font-size-print">
              <p>{{bill.sumQuantity}}</p>
            </td>
            <td class="text-right align-middle font-size-print">
              <p>{{convertPrice(bill.sumUnitAmount)}}</p>
            </td>
            <td class="text-right align-middle font-size-print">
              <p>{{convertPrice(bill.sumTotalDiscount)}}</p>
            </td>
            <td class="text-right align-middle font-size-print">
              <p>{{convertPrice(bill.sumTotalAmount)}}</p>
            </td>
          <tr/>
        </table>

        <p class="content-info font-size-print">
          Điểm tích lũy còn lại: {{ bill.customerPoint }}
        </p>
        <p class="content-info mb-5 font-size-print" v-if="bill.pointUse > 0">
          <span class="label-color">Tiêu điểm tích lũy:</span>
          : {{ bill.subtractPoint }} điểm( {{ convertPrice(bill.pointUse) }})
        </p>
        <p
          class="content-info mb-5 font-size-print"
          v-if="bill.depositAmount > 0"
        >
          <span class="label-color">Tiền đã cọc:</span>
          : {{ convertPrice(bill.depositAmount) }}
        </p>
        <p
          class="content-info mb-5 font-size-print"
          v-if="bill.transferFromOrder > 0"
        >
          <span class="label-color">Tiền đã chuyển khoản:</span>
          : {{ convertPrice(bill.transferFromOrder) }}
        </p>
        <p
          class="content-info mb-5 font-size-print"
          v-if="bill.moneyCredit > 0"
        >
          <span class="label-color">Tiền đã quẹt thẻ:</span>
          : {{ convertPrice(bill.moneyCredit) }}
        </p>
        <p
          class="content-info mb-5 font-size-print"
          v-if="bill.moneyInstallment > 0"
        >
          <span class="label-color">Tiền đã trả góp:</span>
          : {{ convertPrice(bill.moneyInstallment) }}
        </p>
        <p class="content-info mb-5 font-size-print">
          <span class="label-color">Chiết khấu trên hóa đơn</span>
          : {{ convertPrice(bill.discountValue) }}
        </p>
        <p class="content-info mb-5 font-size-print" v-if="bill.tradeIn.finalBuyingPrice > 0">
          <span class="label-color">Tiền thu cũ</span>
          : {{ convertPrice(bill.tradeIn.finalBuyingPrice) }}
        </p>
        <p class="content-info mb-5 font-size-print" v-if="bill.tradeIn.finalBuyingPrice > 0">
          <span class="label-color">Tên sản phẩm thu cũ: {{bill.tradeIn.product.productName}} - (IMEI: {{bill.tradeIn.productIMEI}})</span>
          
        </p>
        <p v-if="bill.conversionFee" class="content-info mb-5 font-size-print">
          <span class="label-color">Phí chuyển đổi</span>
          :
          <span class="font-size-price">{{ convertPrice(bill.conversionFee) }}</span>
        </p>
        <p class="content-info mb-5 font-size-print">
          <span class="label-color">Tổng tiền phải trả</span>
          :
          <span class="font-size-price">{{ convertPrice(bill.totalPay) }}</span>
        </p>

        <p class="content-info font-size-print" style="margin-bottom: 0px">
          <span class="label-color font-size-print"
            >Tổng tiền bằng chữ: {{ bill.textAmount }}</span
          >
        </p>

        <div v-if="bill.orderNote">
          <p class="content-info font-size-print">
            <span class="label-color">Ghi chú của hóa đơn </span>
            : {{ bill.orderNote }}
          </p>
        </div>

        <div v-if="bill.warrantyNote">
          <p class="content-info font-size-warranty">
            <span class="label-color">Ghi chú bảo hành </span>
            <span>: {{ bill.warrantyNote }}</span>
          </p>
        </div>

        <div v-if="productUsages.length > 0">
          <p class="content-info font-size-product-info">
            <span class="label-color">Lưu ý khi sử dụng sản phẩm:</span>
          </p>
          <ul
            style="
              padding-left: 15px;
              line-height: 20px;
              margin-top: 5px;
              margin-left: 5px;
              list-style: circle;
            "
            class="font-size-product-info"
          >
            <div v-for="(productUsage, index) in productUsages" :key="index">
              <li v-if="productUsage.note">
                <p
                  class="font-size-product-info"
                  style="margin-bottom: 0px !important"
                >
                  {{ productUsage.productName }}:
                </p>
                <div class="content-product font-size-product-info">
                  {{ productUsage.note }}
                </div>
              </li>
            </div>
          </ul>
        </div>

        <b-container class="bv-example-row">
          <b-row cols="12">
            <b-col md="4" class="text-center">
              <div>
                <p>
                  <span class="label-color font-size-print">BÁN HÀNG</span>
                </p>
                <p class="font-size-print">
                  {{ bill.saleName }}
                </p>
              </div>
            </b-col>
            <b-col md="4" class="text-center">
              <div>
                <p class="text-center">
                  <span class="label-color font-size-print">THU NGÂN</span>
                </p>
                <p class="font-size-print text-center">
                  {{ bill.cashierName }}
                </p>
              </div>
            </b-col>
            <b-col md="4" class="text-center">
              <div>
                <p class="text-center">
                  <span class="label-color font-size-print">KHÁCH HÀNG</span>
                </p>
                <p class="font-size-print text-center">
                  {{ bill.customerName }}
                </p>
              </div>
            </b-col>
            <b-col></b-col>
          </b-row>
        </b-container>
      </div>
    </b-row>
  </div>
</template>
<script>
import { convertPrice } from '@/utils/common';
import { BILL_ITEM_TYPE, AMOUNT_TYPE, PRODUCT_TYPE } from '@/utils/enum';

export default {
  props: ['bill', 'productUsages'],
  methods: {
    convertPrice,
  },
  data() {
    return {
      BILL_ITEM_TYPE_PRODUCT: BILL_ITEM_TYPE.PRODUCT,
      BILL_ITEM_TYPE_PRODUCT_BONUS: BILL_ITEM_TYPE.PRODUCT_BONUS,
      BILL_ITEM_TYPE_PRODUCT_PROMOTION: BILL_ITEM_TYPE.PRODUCT_PROMTION,
      PRODUCT_TYPE, 
      AMOUNT_TYPE_MONEY: AMOUNT_TYPE.MONEY,
      AMOUNT_TYPE_PERCENT: AMOUNT_TYPE.PERCENT,
    }
  }
};
</script>
<style scoped>
.label-color {
  color: black;
  font-weight: 600;
}
.content-info {
  color: black;
  font-weight: 500;
}
.table-bordered-print th {
  border: 0.5px solid #474545;
}
.table-bordered-print td {
  border: 0.5px solid #474545;
}
.font-size-print {
  color: black;
  font-size: 13px;
}
.font-size-warranty {
  color: black;
  font-size: 13px;
}
.content-product {
  line-height: 1.375;
  white-space: pre-wrap;
  font-size: 13px;
  margin-top: 4px;
}
.font-size-gift {
  color: black;
  font-size: 12px;
}
</style>
